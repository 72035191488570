<template>
  <div class="information-detail">
    <div class="information-detail-body">
      <!-- 新闻详情页 -->
      <div class="information-detail-body-left">
        <div class="title text-ellipsis">
          {{ infoData.title }}
        </div>
        <div class="watch-amount-time">
          <div class="watch-amount">
            <span class="iconfont icon-yanjing"></span>
            <span class="watch-amount-num">
              {{ infoData.views || 0 }}
            </span>
          </div>
          <div class="time">{{ infoData.createDate }}</div>
        </div>
        <div class="text" v-html="infoData.detail"></div>
        <div class="detail-bottom ">
          <div
            class="detail-bottom-item text-ellipsis"
            v-show="infoData.topName && infoData.topId"
            @click="previous(infoData.topId)"
          >
            上一篇：<span>{{ infoData.topName }}</span>
          </div>
          <div
            class="detail-bottom-item text-ellipsis"
            v-show="infoData.underName && infoData.underId"
            :style="{
              marginLeft: infoData.topName && infoData.topId ? '40px' : 0
            }"
            @click="previous(infoData.underId)"
          >
            下一篇：<span>{{ infoData.underName }}</span>
          </div>
        </div>
      </div>
      <div v-show="journalism_type" class="information-detail-body-right">
        <div class="list-title">
          {{ rigthTab.name }}
        </div>
        <div class="information-list">
          <div
            class="item"
            v-for="(item, index) in journalism_list"
            :key="index"
            @click="handel_sidebar(item.id, index)"
          >
            <div v-show="item.vshow">
              <div class="title ellipsis_two">
                {{ item.title }}
              </div>
              <div class="item-img">
                <img :src="$utils.picturePrefix(item.image)" />
              </div>
              <div class="buttom">
                <div class="watch-amount">
                  <span class="iconfont icon-yanjing"></span>
                  <span class="watch-amount-num">
                    {{ item.views || 0 }}
                  </span>
                </div>
                <div class="time">{{ item.createDate }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { session } from "@/utils/sessions";
import Vue from "vue";
export default {
  components: {},
  data() {
    return {
      // 新闻详情页中间展示数据
      infoData: [],
      // id: 1,
      title: "",
      createDate: "",
      introduction: " ",
      detail: "",
      image: "",
      list: [],
      fast_list: [],
      index: "",
      rigth_tab_index: "",
      rigthTab: {},
      journalism_list: [],
      journalism_type: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 咨讯详情
    query_list(id = this.$route.query.id) {
      this.$api.getConsultingDetail({ id }).then(res => {
        let { code, data } = res;
        if (code == 200) {
          this.infoData = data;
          this.list &&
            this.list.forEach((e, index) => {
              if (e.id == this.$route.query.id) {
                this.index = index;
              }
            });
        }
      });
    },
    // 右侧tab 栏获取
    query_rigth_Detail() {
      this.$api.getConsultingTypeSide().then(res => {
        let { code, data } = res;
        if (code == 200) {
          this.rigthTab = data;
          this.getConsulting();
        }
      });
    },

    // 右侧 列表
    getConsulting() {
      this.$api
        .getConsulting({ num: 4, page: 1, typeId: this.rigthTab.id })
        .then(res => {
          let {
            code,
            data: { records }
          } = res;

          if (code == 200) {
            this.journalism_list = [];
            for(let i = 0; i < records.length; i++) {
              if(records[i].id != this.$route.query.id) {
                records[i].vshow = true;
                this.journalism_list.push(records[i]);
              }
            }
            console.log("=====",this.journalism_list);
            this.journalism_list.length > 0 ? this.journalism_type=true : this.journalism_type=false;
          }
        });
    },
    // 右侧点击事件
    handel_sidebar(id, index) {
      if (id != this.$route.query.id) {
        let newItem = this.journalism_list[index];
        newItem.vshow = !newItem.vshow;
        
        this.$router.replace(`/information/detail?id=${id}`);
        this.query_list(id);
        this.getConsulting();
        // Vue.set(this.list, index, newItem);
        
      }
    },
    // 上一篇
    previous(id) {
      if (id != this.$route.query.id) {
        this.$router.replace(`/information/detail?id=${id}`);
        this.query_list(id);
      }
    }
  },
  created() {
    this.query_list();
    this.query_rigth_Detail();
    this.list = session.get_informationList();
  },
  mounted() {
  },
  destroyed() {
    session.remove_informationList();
  }
};
</script>
<style lang="less">
@import "./index.less";
</style>
